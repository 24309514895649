import { useState } from "react";
import b3Customer from '../../../../src/assets/img/b3.png';
import iaas from '../../../../src/assets/img/iaas.png';
import itau from '../../../../src/assets/img/itau.png';
import legacy from '../../../../src/assets/img/legacy.png';
import virgo from '../../../../src/assets/img/virgo.png';

import Slider from "react-slick";
import b3 from '../../../../src/assets/img/b3-logo-1-slide.png';
import bigDataCorp from '../../../../src/assets/img/big-data-corp-slide.png';
import certiSign from '../../../../src/assets/img/certisign-black-slide.png';
import clickSign from '../../../../src/assets/img/click-sign-slide.png';
import docuSign from '../../../../src/assets/img/docusign-1-slide.png';
import microsoftStartup from '../../../../src/assets/img/microsoft-startups-slide.png';
import serPro from '../../../../src/assets/img/serpro-slide.png';

export function ContentOtherSolution() {


    const [lizaRegister, setLizaRegister] = useState(true);
    // const [lizaValid, setLizaValid] = useState(false);
    const [lizaAssembly, setLizaAssembly] = useState(false);

    const [dataManager, setDataManager] = useState(true);
    const [backgroundCheck, setBackgroundCheck] = useState(false);
    const [flow, setFlow] = useState(false);
    const [digitalSignature, setDigitalSignature] = useState(false);

    // const [validProcess, setValidProcess] = useState(false);

    const [assemblyOnline, setAssemblyOnline] = useState(true);


    const handleLizaRegister = () => {
        setLizaRegister(true);
        // setDigitalSignature(false);
        setLizaAssembly(false);
    }

    // const handleLizaValid = () => {
    //     setLizaValid(true);
    //     setLizaRegister(false);
    //     setLizaAssembly(false);
    // }

    const handleLizaAssembly = () => {
        setLizaAssembly(true);
        setLizaRegister(false);
        // setLizaValid(false);
    }

    const handleDataManager = () => {
        setDataManager(true);
        setBackgroundCheck(false);
        setFlow(false);
        setDigitalSignature(false);
    }

    const handleBackgroundCheck = () => {
        setBackgroundCheck(true);
        setDataManager(false);
        setFlow(false);
        setDigitalSignature(false);
    }

    const handleFlow = () => {
        setFlow(true);
        setDataManager(false);
        setBackgroundCheck(false);
        setDigitalSignature(false);
    }

    const handleDigitalSignature = () => {
        setDigitalSignature(true);
        setDataManager(false);
        setBackgroundCheck(false);
        setFlow(false);
    }

    // const handleValidProcess = () => {
    //     setValidProcess(true);
    //     setDigitalSignature(false);
    // }

    const handleAssemblyOnline = () => {
        setAssemblyOnline(true);
    }

    const openSchedule = () => {
        window.open('https://calendly.com/penna26/liza', '_blank')
    }

    let SliderSettings = {
        adaptiveHeight: true,
        slidesToShow: 6,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 1500,
        arrows: false,
        dots: false,
        pauseOnHover: false,
        responsive: [{
            breakpoint: 768,
            settings: {
                slidesToShow: 4
            }
        }, {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
            }
        }]
    }

    return (
        <>
            <div className="content-other-solutions animate__animated animate__fadeInLeft">
                <div className="content-other-solutions-buttons">
                    <div className="other-solution-item-liza">
                        <button className={lizaRegister ? 'active' : ''} onClick={() => handleLizaRegister()}>
                            <h1>Liza Cadastro</h1>
                            <span>Saiba mais</span>
                        </button>
                    </div>
                    {/* <div className="other-solution-item-liza">
                        <button className={lizaValid ? 'active' : ''} onClick={() => handleLizaValid()}>
                            <h1>Liza Valida</h1>
                            <span>Saiba mais</span>
                        </button>
                    </div> */}
                    <div className="other-solution-item-liza">
                        <button className={lizaAssembly ? 'active' : ''} onClick={() => handleLizaAssembly()}>
                            <h1>Liza Assembleia</h1>
                            <span>Saiba mais</span>
                        </button>
                    </div>
                </div>
                <div className="content-sub-items" style={{ background: '#ffffff' }}>
                    <div style={{ width: '100%', height: '1px', background: '#cccccc', marginTop: '1rem', marginBottom: '2rem' }}></div>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: '1rem', alignItems: 'center' }}>
                        {lizaRegister && (
                            <>
                                <div className="sub-item">
                                    <button className={dataManager ? 'active' : ''} onClick={() => handleDataManager()}>Gestão de Dados Cadastrais</button>
                                </div>
                                <div className="sub-item">
                                    <button className={backgroundCheck ? 'active' : ''} onClick={() => handleBackgroundCheck()}>Background Check</button>
                                </div>
                                <div className="sub-item">
                                    <button className={flow ? 'active' : ''} onClick={() => handleFlow()}>Fluxos</button>
                                </div>
                                <div className="sub-item">
                                    <button className={digitalSignature ? 'active' : ''} onClick={() => handleDigitalSignature()}>Assinatura Digital</button>
                                </div>
                            </>
                        )}
                        {/* {lizaValid && (
                            <>
                                <div className="sub-item">
                                    <button className={digitalSignature ? 'active' : ''} onClick={() => handleDigitalSignature()}>Assinatura Digital</button>
                                </div>
                                <div className="sub-item">
                                    <button className={validProcess ? 'active' : ''} onClick={() => handleValidProcess()}>Validacão de um Processo</button>
                                </div>
                            </>
                        )} */}
                        {lizaAssembly && (
                            <>
                                <div className="sub-item">
                                    <button className={assemblyOnline ? 'active' : ''} onClick={() => handleAssemblyOnline()}>Assembleia Online & Votação</button>
                                </div>
                            </>
                        )}
                    </div>
                </div>
                {lizaRegister && dataManager && (
                    <div className="content-wrapper-other-solutions animate__animated animate__fadeInLeft">
                        <div style={{ marginTop: '4rem', textAlign: 'left' }}>
                            <h2>Princípio Open Banking</h2>
                            <span>Plataforma em rede onde cada cliente vira uma conexão responsável por manter atualizado seu perfil com os dados cadastrais e compartilha as informações que desejar e com quem desejar.</span>
                        </div>
                        <div style={{ marginTop: '1rem', textAlign: 'left', paddingBottom: '5rem' }}>
                            <div className="area-product-video">
                                <h3>Demonstração</h3>
                                <iframe style={{ maxWidth: '100%' }} width={'100%'} height={'768px'}
                                    src="https://www.youtube.com/embed/1VaPvCywGeY" title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                )}
                {lizaRegister && backgroundCheck && (
                    <div className="content-wrapper-other-solutions animate__animated animate__fadeInLeft">
                        <div style={{ marginTop: '4rem', textAlign: 'left' }}>
                            <h2>Compliance para onboarding</h2>
                            <span>Processo de consultas de dados para uma análise de risco automatizada.</span>
                        </div>
                        <div style={{ marginTop: '1rem', textAlign: 'left', paddingBottom: '5rem' }}>
                            <div className="area-product-video">
                                <h3>Demonstração</h3>
                                <iframe style={{ maxWidth: '100%' }} width={'100%'} height={'768px'}
                                    src="https://www.youtube.com/embed/WgO7B1Aq6YM" title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                )}
                {lizaRegister && flow && (
                    <div className="content-wrapper-other-solutions animate__animated animate__fadeInLeft">
                        <div style={{ marginTop: '4rem', textAlign: 'left' }}>
                            <h2>Seu processo do seu jeito</h2>
                            <span>Crie seu fluxo de documentos ou formulários para serem preenchidos e assinados pelos seus clientes ou fornecedores.</span>
                        </div>
                        <div style={{ marginTop: '1rem', textAlign: 'left', paddingBottom: '5rem' }}>
                            <div className="area-product-video">
                                <h3>Demonstração</h3>
                                <iframe style={{ maxWidth: '100%' }} width={'100%'} height={'768px'}
                                    src="https://www.youtube.com/embed/TUxzh-xDRD0" title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                )}
                {lizaRegister && digitalSignature && (
                    <div className="content-wrapper-other-solutions animate__animated animate__fadeInLeft">
                        <div style={{ marginTop: '4rem', textAlign: 'left' }}>
                            <h2>Conclua o processo na plataforma</h2>
                            <span>Uma vez validado o processo, utilize nossa integração para assinar documentos de forma digital com a chave pública padrão ICP-Brasil.</span>
                        </div>
                        <div style={{ marginTop: '1rem', textAlign: 'left', paddingBottom: '5rem' }}>
                            <div className="area-product-video">
                                <h3>Demonstração</h3>
                                <iframe style={{ maxWidth: '100%' }} width={'100%'} height={'768px'}
                                    src="https://www.youtube.com/embed/2D2wwNIV92Y" title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                )}
                {/* {lizaValid && validProcess && (
                    <div className="content-wrapper-other-solutions animate__animated animate__fadeInLeft">
                        <div style={{ marginTop: '4rem', textAlign: 'left' }}>
                            <h2>Valide um processo interno baseado no cadastro da outra parte</h2>
                            <span>Insira o tipo de operação e o valor para a Liza poder ajudar na validação da firma e poder de quem vai assinar a operação pela outra parte.</span>
                        </div>
                        <div style={{ marginTop: '1rem', textAlign: 'left', paddingBottom: '5rem' }}>
                            <div className="area-product-video">
                                <h1>Em Breve</h1>
                            </div>
                        </div>
                    </div>
                )} */}
                {lizaAssembly && assemblyOnline && (
                    <div className="content-wrapper-other-solutions animate__animated animate__fadeInLeft">
                        <div style={{ marginTop: '4rem', textAlign: 'left' }}>
                            <h2>Faça a Gestão de uma assembleia em um único local</h2>
                            <span>Dentro da Liza você controla os dados cadastrais, o poder de quem pode assinar a ATA da assembleia, transmite a assembleia convocada em uma sala virtual, contabiliza os votos e procede com a assinatura eletrônica da ATA da assembleia.ø</span>
                        </div>
                        <div style={{ marginTop: '1rem', textAlign: 'left', paddingBottom: '5rem' }}>
                            <div className="area-product-video">
                                <h3>Demonstração</h3>
                                <iframe style={{ maxWidth: '100%' }} width={'100%'} height={'768px'}
                                    src="https://www.youtube.com/embed/1-f-cBtMd5k?si=F-bin_qMzqSXwdla" title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen></iframe>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div id="schedule-a-demo">
                <div className="contentDemo animate__animated animate__fadeInLeft">
                    <h1 className="titleSchedule">Agende uma demonstração</h1>
                    <button type="button" className="button-schedule" onClick={() => openSchedule()}>Agende com um especialista</button>
                </div>
            </div>
            <div id="customers">
                <div className="contentCustomer">
                    <h1 className="title">Clientes</h1>
                </div>
                <div className="bodyCustomer">
                    <div className="item-customer">
                        <a href="https://virgo.inc/" target="_blank" rel="noopener noreferrer"><img src={virgo} width="auto" alt="Virgo"
                            height="auto" /></a>
                    </div>
                    <div className="item-customer">
                        <a href="https://iaasbr.com/" target="_blank" rel="noopener noreferrer"><img src={iaas} width="auto" alt="Iaas br"
                            height="auto" /></a>
                    </div>
                    <div className="item-customer">
                        <a href="https://www.legacycapital.com.br/" target="_blank" rel="noopener noreferrer"><img src={legacy} width="auto"
                            alt="Legacy Capital" height="auto" /></a>
                    </div>
                    <div className="item-customer">
                        <a href="https://www.itau.com.br/" target="_blank" rel="noopener noreferrer"><img src={itau} width="auto"
                            alt="Itaú" height="auto" /></a>
                    </div>
                    <div className="item-customer">
                        <a href="https://www.b3.com.br/" target="_blank" rel="noopener noreferrer"><img src={b3Customer} width="auto"
                            alt="B3" height="auto" /></a>
                    </div>
                </div>
            </div>
            <div id="partners">
                <div className="contentPartners">
                    <h1 className="title">Parceiros</h1>
                </div>
                <Slider {...SliderSettings} className="customer-logos slider">
                    <div className="slide">
                        <a href="https://www.b3.com.br/pt_br/solucoes/plataformas/middle-e-backoffice/imercado/provedores-de-solucoes/"
                            target="_blank" rel="noopener noreferrer"><img src={b3} alt="b3" /></a>
                    </div>
                    <div className="slide">
                        <a href="https://www.clicksign.com/" target="_blank" rel="noopener noreferrer"><img src={clickSign}
                            alt="click sign" /></a>
                    </div>
                    <div className="slide">
                        <a href="https://www.docusign.com/" target="_blank" rel="noopener noreferrer"><img src={docuSign}
                            alt="docusign" /></a>
                    </div>
                    <div className="slide">
                        <a href="https://www.serpro.gov.br/" target="_blank" rel="noopener noreferrer"><img src={serPro}
                            alt="serpro" /></a>
                    </div>
                    <div className="slide">
                        <a href="https://bigdatacorp.com.br/" target="_blank" rel="noopener noreferrer"><img src={bigDataCorp}
                            alt="big data corp" /></a>
                    </div>
                    <div className="slide">
                        <a href="https://certisign.com.br/" target="_blank" rel="noopener noreferrer"><img src={certiSign}
                            alt="certisign" /></a>
                    </div>
                    <div className="slide">
                        <a href="https://foundershub.startups.microsoft.com/" target="_blank" rel="noopener noreferrer"><img
                            src={microsoftStartup} alt="Microsoft for Startups Founders Hub" /></a>
                    </div>
                </Slider>
            </div>
        </>
        
    )
}