import { logEvent } from "firebase/analytics";
import { useEffect } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import '../src/fontawesome-free-5.15.4-web/css/all.css';
import '../src/style/style.css';
import { analytics } from "./environments/environment";
import { NewAccount } from './pages/contanova';
import { CVM175 } from './pages/cvm/175';
import { CVM50 } from './pages/cvm/50';
import { Home } from './pages/home';
import { OtherSolutions } from './pages/other-solutions';
import { Pitch } from './pages/pitch';
import { Whitelabel } from './pages/whitelabel';
// import './services/firebase/analytics';
// import './services/firebase/index';

export function App() {

  useEffect(() => {
    logEvent(analytics, 'page_view', {
      page_title: 'go Liza',
      page_path: '/'
    });
  }, []);


  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />}></Route>
          <Route path='/whitelabel' element={<Whitelabel />}></Route>
          <Route path='/other-solutions' element={<OtherSolutions />}></Route>
          <Route path='/pitch' element={<Pitch />}></Route>
          <Route path='/cvm50' element={<CVM50 />}></Route>
          <Route path='/cvm175' element={<CVM175 />}></Route>
          <Route path='/contanova' element={<NewAccount />}></Route>
        </Routes>
      </BrowserRouter>
    </>
  );
}