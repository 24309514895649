import market from '../../../assets/img/market.png';
export function ContentPitch() {
    return (
        <>
            <div className="headerLiza">
                <h2>Plataforma especializada na gestão de cadastros para o mercado de capitais otimizando a esteira de validação
                    de processos financeiros desde o onboarding até a sua conclusão.
                </h2>


                <div className="institional-video">
                    <iframe src="https://www.youtube.com/embed/uA4y_UjAyso" title="goLiza Institucional" frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen></iframe>
                </div>
            </div>

            <div className="hilight-template">
                <div className="hilight-item box-green">
                    <div className="hilight-icon">
                        <span className="material-icons">
                            group
                        </span>
                    </div>
                    <div className="hilight-description">
                        <p>Parceiro homologado pela B3</p>
                    </div>
                </div>
                <div className="hilight-item box-blue">
                    <div className="hilight-icon">
                        <span className="material-icons">
                            rocket_launch
                        </span>
                    </div>
                    <div className="hilight-description">
                        <p>Acelerada pela Darwin Startups e Visa Startups</p>
                    </div>
                </div>
                <div className="hilight-item box-green">
                    <div className="hilight-icon">
                        <span className="material-icons">
                            legend_toggle
                        </span>
                    </div>
                    <div className="hilight-description">
                        <p>Mais de 60% de crescimento mensal</p>
                    </div>
                </div>
                <div className="hilight-item box-blue">
                    <div className="hilight-icon">
                        <span className="material-icons">
                            settings
                        </span>
                    </div>
                    <div className="hilight-description">
                        <p>Estratégia de negócio Asset Light</p>
                    </div>
                </div>
                <div className="hilight-item box-green">
                    <div className="hilight-icon">
                        <span className="material-icons">
                            work
                        </span>
                    </div>
                    <div className="hilight-description">
                        <p>Oportunidade de mercado: IDTech para empresas</p>
                    </div>
                </div>
                <div className="hilight-item box-blue">
                    <div className="hilight-icon">
                        <span className="material-icons">
                            emoji_events
                        </span>
                    </div>
                    <div className="hilight-description">
                        <p>Ausência de solução concorrente direta</p>
                    </div>
                </div>
            </div>

            <div className="content-info">
                <div className="info-item">
                    <h2 className="pitch-title">Problema</h2>
                    <span>O mercado financeiro possui uma grande ineficiência no processo de cadastramento. Todas as transações
                        são tratadas de forma particular e os players do mercado precisam gerir o envio e manutenção de uma
                        série de documentos e cadastros toda a vez que uma transação se inicia. O estado atual de esteiras de
                        validação de processos financeiros, do onboarding à conclusão das transações e posterior gestão do
                        cadastro, tem focado em soluções que não são escaláveis e pouco automatizadas por serem manuais e
                        despadronizadas.</span>
                </div>
                <div className="info-item">
                    <h2 className="pitch-title">A Empresa</h2>
                    <span>A goLiza nasceu com a missão de padronizar o onboarding e cadastro do mercado financeiro, que possui
                        dezenas de milhares de instituições. Apesar da dor latente, as barreiras de entrada e o ciclo de venda
                        são desafiadores e demandam uma solução pronta que viabilize uma adoção rápida pelos usuários. Criamos
                        um ecossistema que conecta diversas instituições, simplifica todo o processo de compartilhamento de
                        dados com segurança e políticas de acesso.</span>
                </div>
                <div className="info-item">
                    <h2 className="pitch-title">Modelo de negócio</h2>
                    <span>Nosso modelo é baseado em uma mensalidade que varia pelo número de conexões do cliente com outras
                        empresas. Essa cobrança é feita a partir do momento que o cliente estabelece mais de 50 conexões
                        (freemium). Conforme o número de empresas que ele se conecta pela plataforma, maiores são os descontos
                        unitários por conexão. Para os serviços agregados que a goLiza também oferece como o Liza Assembleia e
                        outros que entrarão em operação ao longo de 2022, esses serviços são cobrados conforme
                        utilização.</span>
                </div>
                <div className="info-item">
                    <h2 className="pitch-title">Mercado</h2>
                    <span>O mercado alvo da goLiza é o de capitais. Os principais players são: gestoras de fundos de
                        investimento, bancos, securitizadoras, corretoras, DTVMs, agentes fiduciários, administradoras e
                        investidores (empresas). Atualmente a despesa total com consultas cadastrais é de R$ 3 bilhões por
                        ano.</span>
                </div>
            </div>

            <div className="content-market">
                <img src={market} width="auto" height="auto" className="responsive" alt="market" />
            </div>

            <div className="content-info">
                <div className="info-item">
                    <h2 className="pitch-title">Diferencial</h2>
                    <span>Somos a única empresa que oferece uma plataforma onde o cliente consegue fazer a esteira de validação
                        de um processo financeiro do começo ao fim, sem Alt+Tab ou integrações entre sistemas para ter essa
                        esteira dentro de casa. A goLiza conseguiu desenvolver essa esteira em um único lugar através de
                        tecnologia proprietária e flexibilidade em se integrar a várias plataformas / serviços
                        complementares.</span>
                </div>
                <div className="info-item">
                    <h2 className="pitch-title">Fundadores</h2>
                    <h3>Sergio Penna</h3>
                    <div className="info-type">
                        <span>CEO & Founder</span>
                    </div>
                    <span>Mais de 24 anos de experiência profissional sendo 10 anos em posições Executivas em
                        empresas
                        multinacionais (S&P 500) tendo liderado projetos nacionais e internacionais e 14 anos como
                        empreendedor.
                        Perfil generalista com experiência no desenvolvimento e implementação de estratégias corporativas
                        incluindo lançamentos de produto, fusões e aquisições (M&A), planos de negócio, planejamento
                        estratégico, planejamento de marketing, planejamento financeiro e gestão de projetos.</span>
                    <h3>Fisher Venture Builder</h3>
                    <div className="info-type">
                        <span>Co-Founder</span>
                    </div>
                    <span>Formada por sócios com experiência em empreendedorismo, no mercado financeiro e em consultoria
                        estratégica, a Fisher co-cria startups (7 total, sendo 1 exit) e aplica sua metodologia própria para
                        acelerar o crescimento das startups do portfólio, além de criar um ecossistema de colaboração entre
                        elas. A Fisher é a primeira Venture Builder brasileira a ser selecionada para a GSSN (Global Startup
                        Studio Network), a mais renomada rede de Startup Studios do mundo, um convite que atesta o alto nível e
                        o padrão internacional da Fisher.</span>
                </div>
            </div>

            <div className="content-info-video">
                <h2>Vídeos</h2>
                <div className="content-video">
                    <div className="video-item">
                        <iframe loading="lazy" src="https://www.youtube.com/embed/1-f-cBtMd5k" title="goLiza Institucional" frameborder="0"
                            className="video"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                    </div>
                    <div className="video-item">
                        <iframe loading="lazy" src="https://www.youtube.com/embed/2D2wwNIV92Y" title="goLiza Institucional" frameborder="0"
                            className="video"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                    </div>
                    <div className="video-item">
                        <iframe loading="lazy" src="https://www.youtube.com/embed/WgO7B1Aq6YM" title="goLiza Institucional" frameborder="0"
                            className="video"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                    </div>

                    <div className="video-item">
                        <iframe loading="lazy" src="https://www.youtube.com/embed/pYmLh0eBpzY" title="goLiza Institucional" frameborder="0"
                            className="video"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                    </div>
                    <div className="video-item">
                        <iframe loading="lazy" src="https://www.youtube.com/embed/1VaPvCywGeY" title="goLiza Institucional" frameborder="0"
                            className="video"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                    </div>
                    <div className="video-item">
                        <iframe loading="lazy" src="https://www.youtube.com/embed/nrAQHVvfMSM" title="goLiza Institucional" frameborder="0"
                            className="video"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen></iframe>
                    </div>
                </div>
            </div>

            <div className="content-info">
                <div className="info-item">
                    <h2 className="pitch-title">Matérias</h2>
                    <div className="pitch-links">
                        <span>Snaq</span> - <a href="https://www.snaq.co/news/goliza-recebe-captacao-da-virgo-ventures"
                            className="link">goLiza, startup que otimiza e agiliza processos cadastrais,
                            recebe a primeira investida da Virgo Ventures</a>
                        <br />
                        <span>Startupi</span> - <a
                            href="https://startupi.com.br/2021/07/virgo-ventures-anuncia-primeiro-investimento-em-startup-de-cadastros-onboarding-e-conexao-entre-empresas/"
                            className="link">Virgo Ventures anuncia primeiro investimento em startup de cadastros, onboarding e
                            conexão
                            entre empresas</a>
                        <br />
                        <span>B3</span> - <a
                            href="https://www.b3.com.br/pt_br/solucoes/plataformas/middle-e-backoffice/imercado/provedores-de-solucoes/"
                            className="link">goLiza é a primeira parceira a integrar o fluxo do iMercado Conciliação da B3</a>
                        <br />
                        <span>Virgo</span> - <a
                            href="https://virgo.inc/virgo-lanca-vertical-de-ventures-e-anuncia-primeiro-investimento-na-startup-goliza/"
                            className="link">Virgo lança vertical de Ventures e anuncia primeiro investimento na startup goLiza</a>
                        <br />
                        <span>Baguete</span> - <a
                            href="https://www.baguete.com.br/noticias/22/12/2021/virgo-busca-startups-para-investir-r-20-mi"
                            className="link">Virgo busca startups para investir R$ 20 mi</a>
                        <br />
                        <span>The Startup Pill</span> - <a
                            href="https://startupill.com/64-best-brazil-business-information-systems-startups/" className="link">64
                            Best
                            Brazil Business Information Systems Startup</a>
                        <br/>
                        <span>Weekly Tech Update</span> - <a href="https://juliadeluca.substack.com/p/startups-to-watch-38c" className="link">Startups to Watch</a>
                    </div>
                </div>
                <div className="info-item">
                    <h2 className="pitch-title">Pitch Técnico</h2>
                    <a href="https://drive.google.com/file/d/1bd3aLxwS8qXwsjiu6skEYt6AonhiprE9/view" className="bold">Pitch
                        Técnico</a>
                </div>
            </div>
        </>
    )
}