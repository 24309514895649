export function Ads({ text }) {
    return (
        <>
            <div className="lz-card-ad">
                <div className="lz-ad-text">
                    <span><b>{text}</b><span className="colorPrimary"><b> goLiza</b></span></span>
                </div>
                <div className="lz-ad-button">
                    <a href="https://goliza.com.br/contanova" className="lz-create-account-primary">Crie sua conta
                        grátis</a>
                </div>
            </div>
        </>
    );
}