import { TopbarCVM } from './../../shareds/components/topbarCVM/index';
import { FooterCVM } from './../../shareds/components/footerCVM/index';
import { CardCVM } from '../../shareds/components/cardCVM';
import { Ads } from '../../shareds/components/Ads';

function HeaderItem({ title, subTitle, pharse }) {
    return (
        <>
            <div className="lz-content-header">
                <div className="lz-item-header-text">
                    <h1><b>{title}</b></h1>
                </div>
                <div className="lz-item-header-caption">
                    <span style={{ fontSize: "16px" }}>{subTitle}</span>
                </div>
            </div>
            <div className="content-sub">
                <h4>
                    <b>{pharse}</b>
                </h4>
            </div>
        </>
    );
}

function ContentDescription({ content }) {
    return (
        <>
            <div className="lz-content-description">
                <h1>
                    <b>{content}</b>
                </h1>
            </div>
        </>
    );
}

function Text({ content, link }) {
    return (
        <>
            <div className="content-article">
                <span style={{ fontSize: "14px" }}><b>
                    {content} <a
                        href={link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="colorPrimary">
                        Clique
                        aqui</a></b>
                </span>
            </div>
        </>
    );
}

export function CVM50() {
    return (
        <>
            <TopbarCVM></TopbarCVM>
            <div className='lz-content'>

                <HeaderItem
                    title="Resolução 50 da CVM e a importância da gestão de dados cadastrais dos seus clientes."
                    subTitle="A Resolução nº 50 da Comissão de Valores Mobiliários (CVM) é uma regulamentação que trata de
                        questões
                        relacionadas aos registros e controles das corretoras de valores mobiliários.
                        No que se refere ao controle de cadastros dos clientes, a Resolução 50 exige que as corretoras
                        mantenham
                        registros atualizados e completos dos seus clientes, incluindo informações como dados pessoais,
                        informações financeiras e informações sobre as transações realizadas.
                        Além disso, a resolução também estabelece que as corretoras devem verificar a identidade dos seus
                        clientes antes de aceitá-los como clientes e manter essas informações armazenadas por um período
                        mínimo
                        de cinco anos.
                        A finalidade destas exigências é garantir a proteção dos investidores e promover a transparência e
                        integridade do mercado de valores mobiliários."
                    pharse="Abaixo separamos o índice da resolução 50 destacando os trechos que mencionam as obrigações e
                    responsabilidades na gestão de dados cadastrais dos clientes no mercado de capitais">
                </HeaderItem>

                <CardCVM>
                    <div className="content-chapter">
                        <h4 className="colorSecondary">
                            <b>CAPÍTULO I – ÂMBITO, DEFINIÇÕES E FINALIDADE</b>
                        </h4>
                    </div>
                    <div className='customSpan'>
                        <div className="content-article">
                            <span>Art. 1º São disciplinados pela presente Resolução:</span>
                        </div>

                        <div className="content-article">
                            <span className="colorPrimary">II – a identificação e o cadastro de clientes, assim como as diligências
                                contínuas visando à coleta de
                                informações suplementares e, em especial, à identificação de seus respectivos beneficiários
                                finais;</span>
                        </div>

                        <div className="content-article">
                            <span>Art. 2º Para fins da presente Resolução, considera-se:</span>
                        </div>

                        <div className="content-article">
                            <span className="colorPrimary">IV – <span className="colorPrimary"><b>cadastro:</b></span> registro, em meio
                                físico ou <span className="colorPrimary"><b>eletrônico</b></span>, das informações e dos documentos de
                                identificação de clientes com os quais a instituição mantém relacionamento direto em função da
                                prestação
                                de serviços no mercado de valores mobiliários;</span>
                        </div>

                        <div className="content-article">
                            <span>Art. 3º Sujeitam-se às obrigações previstas nesta Resolução, no limite de suas atribuições:</span>
                        </div>

                        <div className="content-article">
                            <span className="colorPrimary lz-spacing-text">I – as pessoas naturais ou jurídicas que prestem no mercado
                                de valores
                                mobiliários, em caráter
                                permanente ou eventual, os serviços relacionados à distribuição, custódia, intermediação, ou
                                administração de carteiras;
                            </span>
                            <span className="colorPrimary lz-spacing-text">II – entidades administradoras de mercados organizados e as
                                entidades
                                operadoras
                                de infraestrutura do
                                mercado financeiro;
                            </span>
                            <span className="colorPrimary lz-spacing-text">III – as demais pessoas referidas em regulamentação
                                específica que prestem
                                serviços no mercado de
                                valores mobiliários, incluindo:
                            </span>
                            <span className="colorPrimary">a) os escrituradores;</span>
                            <span className="colorPrimary">b) os consultores de valores mobiliários;</span>
                            <span className="colorPrimary">c) as agências de classificação de risco;</span>
                            <span className="colorPrimary">d) os representantes de investidores não residentes; e</span>
                            <span className="colorPrimary">e) as companhias securitizadoras; e</span>
                            <span className="colorPrimary">IV – os auditores independentes no âmbito do mercado de valores
                                mobiliários.</span>
                        </div>
                    </div>

                </CardCVM>

                <CardCVM>
                    <div className="content-chapter">
                        <h4 className="colorSecondary">
                            <b>CAPÍTULO II – POLÍTICA DE PLD/FTP, AVALIAÇÃO INTERNA DE RISCO E REGRAS, PROCEDIMENTOS E CONTROLES
                                INTERNOS</b>
                        </h4>
                    </div>

                    <div className='customSpan'>
                        <div className="content-article">
                            <span className="colorSecondary">Seção I – Política de Prevenção à Lavagem de Dinheiro, ao Financiamento
                                do
                                Terrorismo e ao
                                Financiamento da Proliferação de Armas de Destruição em Massa</span>
                        </div>

                        <div className="content-article">
                            <span>Art. 4º As pessoas jurídicas mencionadas nos incisos I a III do art. 3º desta Resolução devem
                                elaborar
                                e implementar política de PLD/FTP contendo, no mínimo:</span>
                        </div>

                        <div className="content-article">
                            <span className="lz-spacing-text">II – a descrição da metodologia para tratamento e mitigação dos riscos
                                identificados, a qual deve
                                amparar os parâmetros estabelecidos na avaliação interna de risco, contemplando o detalhamento das
                                diretrizes:</span>
                            <span className="lz-spacing-text">a) que fundamentaram a abordagem baseada em risco adotada;</span>
                            <span className="lz-spacing-text">b) para continuamente conhecer:</span>
                            <span className="colorPrimary lz-spacing-text">1. os clientes ativos, incluindo procedimentos de
                                verificação, coleta,
                                validação
                                e atualização de
                                informações cadastrais, bem como demais diligências aplicáveis, de acordo com os arts. 11 e 17;
                                e</span>
                            <span className="lz-spacing-text">2. os funcionários e os prestadores de serviços relevantes;</span>
                            <span className="lz-spacing-text">c) utilizadas para nortear as diligências visando à identificação do
                                beneficiário final do
                                respectivo
                                cliente, conforme os incisos III e IX e o parágrafo único do art. 2º, arts. 13 a 15 e inciso IV do
                                art.
                                17;</span>
                            <span className="lz-spacing-text">d) de monitoramento e possível detecção das atipicidades, conforme inciso
                                III do art. 17 e art.
                                20,
                                bem como a especificação de outras situações de monitoramento reforçado; e</span>
                            <span className="colorPrimary lz-spacing-text">e) acerca dos critérios utilizados para a obtenção dos
                                indicadores de
                                efetividade
                                da abordagem baseada
                                em risco utilizada para fins de PLD/FTP;</span>
                            <span className="colorPrimary lz-spacing-text">III – definição dos critérios e periodicidade para
                                atualização dos cadastros
                                dos
                                clientes ativos, de
                                acordo com o art. 11, observando-se o intervalo máximo de 5 (cinco) anos;</span>
                        </div>

                        <div className="content-article">
                            <span className="colorSecondary">Seção II – Avaliação Interna de Risco</span>
                        </div>

                        <div className="content-article">
                            <span className="colorSecondary">Seção III – Regras, Procedimentos e Controles Internos</span>
                        </div>
                    </div>

                </CardCVM>

                <Ads text="A forma mais ágil para cadastro de informações e documentos dos seus clientes?"></Ads>

                <CardCVM>
                    <div className="content-chapter">
                        <h4 className="colorSecondary">
                            <b>CAPÍTULO III – RESPONSABILIDADES</b>
                        </h4>
                    </div>

                    <div className='customSpan'>
                        <div className="content-article">
                            <span className="colorSecondary">Seção I – Responsabilidade do Diretor</span>
                            <span className="colorSecondary">Seção II – Responsabilidade dos Órgãos da Alta Administração</span>
                            <span className="colorSecondary">Seção III – Responsabilidade do Auditor Independente Pessoa Natural e do
                                Representante do Auditor
                                Independente Pessoa Jurídica.</span>
                        </div>
                    </div>

                </CardCVM>

                <CardCVM>
                    <div className="content-chapter">
                        <h4 className="colorSecondary">
                            <b>CAPÍTULO IV – PROCESSO DE IDENTIFICAÇÃO DOS CLIENTES</b>
                        </h4>
                        <span>Seção I – Cadastro e Identificação de Beneficiário Final</span>
                    </div>

                    <div className='customSpan'>
                        <div className="content-article">
                            <span className="colorPrimary">
                                § 1º As pessoas mencionadas nos incisos I a III do art. 3º devem continuamente difundir perante seus
                                clientes a <span className="colorPrimary"><b>importância da manutenção de seus dados cadastrais
                                    atualizados</b></span>, disponibilizando import {FooterCVM} from './../../shareds/components/footerCVM/index';
                                canais para
                                que esses investidores e seus representantes, conforme o caso, comuniquem quaisquer atualizações,
                                observado o disposto no inciso II do art. 2º do Anexo B.
                            </span>
                        </div>
                        <div className="content-article">
                            <span className="colorPrimary">
                                Art. 12. <span className="colorPrimary"><b>É permitida a adoção de sistemas alternativos de cadastro,
                                    inclusive por meio eletrônico</b></span>, desde
                                que as soluções adotadas satisfaçam os objetivos das normas vigentes e os procedimentos sejam
                                passíveis
                                de verificação.
                            </span>
                        </div>
                        <div className="content-article">
                            <span className="colorPrimary">
                                Parágrafo único. <span className="colorPrimary"><b>A assinatura do cliente ou de seu procurador no
                                    cadastro pode ser efetuada por meio
                                    digital</b></span>, ou, no caso de sistemas eletrônicos, suprida por outros mecanismos,
                                desde
                                que os procedimentos
                                adotados permitam confirmar com precisão a identificação do cliente.
                            </span>
                        </div>

                        <div className="content-article">
                            <span className="colorSecondary">Seção II – Diligências Devidas Relativas ao Processo de Conhecimento dos
                                Clientes</span>
                            <span>Subseção I – Diligências Devidas pelas Pessoas de que Tratam os Incisos I a III do art. 3º</span>
                            <span>Subseção II – Diligências Devidas pelos Auditores Independentes</span>
                        </div>
                    </div>
                </CardCVM>

                <Ads text="Manutenção dos dados cadastrais dos seus clientes com um clique?"></Ads>

                <CardCVM>
                    <div className="content-chapter">
                        <h4 className="colorSecondary">
                            <b>CAPÍTULO V – MONITORAMENTO, ANÁLISE E COMUNICAÇÃO DAS OPERAÇÕES E SITUAÇÕES SUSPEITAS</b>
                        </h4>

                        <div className='customSpan'>
                            <div className="content-article">
                                <span className="colorSecondary">Seção I – Monitoramento de Operações</span>
                                <span className="colorSecondary">Seção II – Análise de Operações</span>
                                <span className="colorSecondary">Seção III – Comunicação de Operações</span>
                            </div>
                        </div>

                    </div>
                </CardCVM>

                <CardCVM>
                    <div className="content-article">
                        <h4 className="colorSecondary">
                            <b>CAPÍTULO VI – REGISTRO DE OPERAÇÕES E MANUTENÇÃO DE ARQUIVOS</b>
                        </h4>
                    </div>
                </CardCVM>

                <CardCVM>
                    <div className="content-article">
                        <h4 className="colorSecondary">
                            <b>CAPÍTULO VII – CUMPRIMENTO DE SANÇÕES IMPOSTAS POR RESOLUÇÕES DO CSNU</b>
                        </h4>
                    </div>
                </CardCVM>

                <CardCVM>
                    <div className="content-article">
                        <h4 className="colorSecondary">
                            <b>CAPÍTULO VIII - DISPOSIÇÕES FINAIS</b>
                        </h4>
                    </div>

                    <div className='customSpan'>
                        <div className="content-article">
                            <span>
                                ANEXO A - Dispõe sobre as Pessoas Expostas Politicamente de que trata o art. 5, inciso I Art.
                            </span>
                            <span>
                                ANEXO B - Dispõe sobre o conteúdo do cadastro de investidores de que trata o art. 11
                            </span>
                            <span>
                                ANEXO C - Dispõe sobre o conteúdo do cadastro simplificado de que trata o art. 11
                            </span>
                        </div>
                    </div>

                </CardCVM>

                <Text
                    content="Caso queira ter acesso a Resolução 50 da CVM na íntegra:"
                    link="https://conteudo.cvm.gov.br/legislacao/resolucoes/resol050.html">
                </Text>
            </div>

            <ContentDescription
                content="Como atender à resolução 50 da CVM com relação à gestão de dados cadastrais para os seus clientes.">
            </ContentDescription>

            <div className='lz-content'>

                <div className='customSpan'>
                    <div className="content-article">
                        <span>
                            Recuperando o Art. 12 da resolução 50 da CVM temos o seguinte:
                        </span>
                    </div>
                </div>

                <CardCVM>
                    <div className='customSpan'>
                        <div className="content-article">
                            <span className="italic colorPrimary"><b>
                                Art. 12. É permitida a adoção de sistemas alternativos de cadastro, inclusive por meio
                                eletrônico, desde
                                que as soluções adotadas satisfaçam os objetivos das normas vigentes e os procedimentos sejam
                                passíveis
                                de verificação.</b>
                            </span>
                        </div>

                        <div className="content-article">
                            <span>
                                Portanto, é mais vantajoso em termos financeiros e tempo de implementação para empresa, adotar
                                sistemas
                                hoje disponíveis no mercado que atendam à norma ao invés de buscar desenvolver por conta
                                própria.
                            </span>
                            <span>
                                Porém ao procurar o sistema para sua empresa certifique-se que ele cobre os seguintes
                                requisitos:
                            </span>
                        </div>
                    </div>
                </CardCVM>

                <CardCVM>
                    <div className='customSpan'>
                        <div className="content-article">
                            <span><b>
                                1) O sistema permite que o cliente não só envie a informação mas possa atualizar quando achar
                                necessário? </b>
                            </span>
                            <a href="https://www.youtube.com/watch?v=1VaPvCywGeY&ab_channel=goLiza" target="_blank" rel="noopener noreferrer"
                                className="colorPrimary"><b>(Clique aqui e veja exemplo)</b></a>

                            <span><b>
                                2) É um meio eletrônico de guardar informações e documentos? </b>
                            </span>
                            <a href="https://www.youtube.com/watch?v=5EcZgmRx8Sk&ab_channel=goLiza" target="_blank" rel="noopener noreferrer"
                                className="colorPrimary"><b>(Clique aqui e veja exemplo)</b></a>

                            <span><b>
                                3) O sistema possui forma de obtenção de indicadores de efetividade para fins de PLD?</b>
                            </span>
                            <a href="https://www.youtube.com/watch?v=WgO7B1Aq6YM&ab_channel=goLiza" target="_blank" rel="noopener noreferrer"
                                className="colorPrimary"><b>(Clique aqui e veja exemplo)</b></a>

                            <span><b>
                                4) O sistema possui integração com os principais provedores de assinatura eletrônica do
                                Brasil?</b>
                            </span>
                            <a href="https://www.youtube.com/watch?v=2D2wwNIV92Y&ab_channel=goLiza" target="_blank" rel="noopener noreferrer"
                                className="colorPrimary"><b>(Clique aqui e veja exemplo)</b></a>

                        </div>
                    </div>
                </CardCVM>
            </div>

            <FooterCVM></FooterCVM>
        </>
    );
}