import { TopbarCVM } from './../../shareds/components/topbarCVM/index';
import { FooterCVM } from './../../shareds/components/footerCVM/index';
import { CardCVM } from '../../shareds/components/cardCVM';
import { Ads } from './../../shareds/components/Ads';

function HeaderItem({ title, subTitle, pharse }) {
    return (
        <>
            <div className="lz-content-header">
                <div className="lz-item-header-text">
                    <h1><b>{title}</b></h1>
                </div>
                <div className="lz-item-header-caption">
                    <span style={{ fontSize: "16px" }}>{subTitle}</span>
                </div>
            </div>
            <div className="content-sub">
                <h4>
                    <b>{pharse}</b>
                </h4>
            </div>
        </>
    );
}

export function CVM175() {
    return (
        <>
            <TopbarCVM></TopbarCVM>
            <div className='lz-content'>
                <HeaderItem
                    title="Resolução CVM 175 e a importância da gestão de dados cadastrais dos clientes."
                    subTitle="A Resolução CVM 175 dispõe sobre a constituição, o funcionamento e a divulgação de informações dos fundos de investimento, bem como sobre a prestação de serviços para os fundos, e revoga as normas que especifica."
                    pharse="Abaixo destacamos as mudanças que dizem respeito à gestão de dados cadastrais.">
                </HeaderItem>

                <CardCVM>
                    <div className="content-chapter">
                        <h4 className="colorSecondary">
                            <b>CAPÍTULO IV – COTAS</b>
                        </h4>
                    </div>

                    <div className="content-article">
                        <span className="colorSecondary">Seção III – Distribuição</span>
                    </div>

                    <div className="content-article">
                        <span className="colorSecondary">Subseção V – Investimento por Conta e Ordem</span>
                    </div>

                    <div className="content-article">
                        <span>Art. 36. <span className="colorPrimary"><b>Os distribuidores que estejam atuando por conta e ordem de
                            clientes assumem todos os ônus
                            e responsabilidades relacionadas aos clientes, inclusive quanto a seu cadastramento</b></span>,
                            identificação e
                            demais procedimentos que, na forma desta Resolução, caberiam originalmente ao administrador, em
                            especial no que se refere:</span>
                    </div>

                    <div className="content-article">

                        <span className="lz-spacing-text">I – ao fornecimento de regulamentos, termos de adesão e ciência de riscos,
                            notas de investimento e extratos que sejam encaminhados pelos administradores aos distribuidores
                            para tal finalidade;
                        </span>

                        <span className="lz-spacing-text">II – à responsabilidade de dar ciência aos investidores de que a
                            distribuição é feita por conta e ordem;
                        </span>

                        <span className="lz-spacing-text">III – <span className="colorPrimary"><b>ao controle e à manutenção</b></span> de
                            registros internos
                            referentes à compatibilidade entre as movimentações dos recursos dos clientes e sua capacidade
                            financeira e atividades econômicas, nos termos da regulamentação específica sobre prevenção à
                            lavagem de dinheiro, ao financiamento do terrorismo e ao financiamento da proliferação de armas de
                            destruição em massa – PLD/FTP;
                        </span>

                        <span className="lz-spacing-text">IV – <span className="colorPrimary"><b>à regularidade e guarda da documentação
                            cadastral dos clientes</b></span>, nos
                            termos da regulamentação em vigor, bem como pelo cumprimento de todas as exigências legais quanto à
                            referida documentação cadastral;
                        </span>

                        <span className="lz-spacing-text">V – à prestação de informação diretamente à CVM sobre os dados
                            cadastrais dos cotistas efetivos, quando esta informação for solicitada;
                        </span>

                        <span className="lz-spacing-text">VI – <span className="colorPrimary"><b>à comunicação aos cotistas efetivos sobre
                            a convocação de assembleias
                            de cotistas</b></span> e sobre suas deliberações, de acordo com as instruções e informações que, com
                            antecedência suficiente e tempestivamente, receberem dos administradores, observado, ainda, o
                            disposto no art. 38;
                        </span>

                        <span className="lz-spacing-text">VII – à manutenção de serviço de atendimento aos cotistas efetivos, para
                            esclarecimento de dúvidas e pelo recebimento de reclamações;
                        </span>

                        <span className="lz-spacing-text">VIII – ao zelo para que o cotista efetivo tenha pleno acesso a todos os
                            documentos e informações previstos nesta Resolução, em igualdade de condições com os demais cotistas
                            da classe objeto da aplicação;
                        </span>

                        <span className="lz-spacing-text">IX – à manutenção de informações atualizadas que permitam a identificação
                            dos cotistas finais, a qualquer tempo, bem como do registro atualizado de todas as aplicações e
                            resgates realizados em nome de cada um dos cotistas efetivos; e
                        </span>

                        <span className="lz-spacing-text">X – à obrigação de efetuar a retenção e o recolhimento dos tributos
                            incidentes nas aplicações, amortizações e resgates de cotas, conforme determinar a legislação
                            tributária.
                        </span>
                    </div>
                </CardCVM>
            </div>

            <Ads text="A forma mais ágil para cadastro e manutenção de informações e documentos dos seus clientes ?"></Ads>

            <div className='lz-content'>
                <CardCVM>
                    <div className="content-chapter">
                        <h4 className="colorSecondary">
                            <b>CAPÍTULO VII – ASSEMBLEIA DE COTISTAS</b>
                        </h4>
                    </div>

                    <div className="content-article">
                        <span>Seção II – Convocação e Instalação</span>
                    </div>

                    <div className="content-article">
                        <span>Art. 75. A assembleia de cotistas pode ser realizada:</span>
                    </div>

                    <div className="content-article">
                        <span className="lz-spacing-text">I – de modo exclusivamente eletrônico, caso os cotistas somente possam
                            participar e votar por meio de comunicação escrita ou sistema eletrônico; ou</span>
                        <span className="lz-spacing-text">II – de modo parcialmente eletrônico, caso os cotistas possam participar e
                            votar tanto presencialmente quanto a distância por meio de comunicação escrita ou sistema
                            eletrônico.</span>
                        <span className="lz-spacing-text">§ 1º A assembleia realizada exclusivamente de modo eletrônico é
                            considerada como ocorrida na sede do administrador.</span>
                        <span className="colorPrimary lz-spacing-text"><b>§ 2º No caso de utilização de modo eletrônico, o
                            administrador deve adotar meios para garantir a autenticidade e a segurança na transmissão de
                            informações, particularmente os votos, que devem ser proferidos por meio de assinatura eletrônica ou
                            outros meios eficazes para assegurar a identificação do cotista.</b></span>

                        <span className="lz-spacing-text">§ 3º Os cotistas podem votar por meio de comunicação escrita ou
                            eletrônica, desde que recebida pelo administrador antes do início da assembleia, observado o
                            disposto no regulamento.</span>
                    </div>
                </CardCVM>
            </div>

            <Ads text="Assembleia de modo eletrônico com possibilidade de votação e assinatura eletrônica, tudo em um único local ?"></Ads>

            <div className='lz-content'>
                <div className="content-article">
                    <span><b>
                        Caso queira ter acesso a Resolução CVM 175 na íntegra: <a
                            href="https://conteudo.cvm.gov.br/legislacao/resolucoes/resol175.html" target="_blank"
                            className="colorPrimary">
                            Clique
                            aqui</a></b>
                    </span>
                </div>

                <div className="content-article">
                    <span><b>
                        Também recomendamos o paper da BR Governance: <a
                            href="https://brgovernance.com/wp-content/uploads/2023/01/BR-GOV-NOVA-NORMA-DE-FUNDOS-RCVM-175_2.pdf"
                            target="_blank" className="colorPrimary">
                            Clique
                            aqui</a></b>
                    </span>
                </div>
            </div>

            <FooterCVM></FooterCVM>
        </>
    );
}