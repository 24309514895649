export function HeaderWhiteLabel() {
    return (
        <>
            <div className="headerLizaWL">
                <h1>Fintech as a Service goLiza</h1>
                <span>
                    Tenha a sua própria esteira de cadastro
                </span>

                <a className="buttonLizaWL" type="button" href={"https://calendly.com/penna26/liza?month=2021-12"} rel="noopener noreferrer" target={"_blank"} >
                    <p>Agende com um especialista</p>
                </a>

            </div>
        </>
    )
}