export function HeaderOtherSolution() {
    return (
        <>
            <div className="headerLizaWL">
                <h1>Outras Soluções</h1>

                <a className="buttonLizaWL" type="button" href={"https://calendly.com/penna26/liza"} rel="noopener noreferrer" target={"_blank"} >
                    <p>Agende com um especialista</p>
                </a>

            </div>
        </>
    )
}