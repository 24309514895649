import { Topbar } from '../../shareds/components/topbar/index';
import { Footer } from '../../components/footer';
import { HeaderWhiteLabel } from '../../shareds/components/headerWhiteLabel/index';
import { ContentWhiteLabel } from '../../shareds/components/contentWhiteLabel/index';

export function Whitelabel() {
    const newTitle = "goLiza";
    document.title = newTitle
    return (
        <>
            <Topbar hiddenMenu={false}></Topbar>
            <HeaderWhiteLabel></HeaderWhiteLabel>
            <ContentWhiteLabel></ContentWhiteLabel>
            <Footer></Footer>
        </>
    )
}