import { useEffect } from 'react';
import { Content } from '../../components/content';
import { Footer } from '../../components/footer';
import { Header } from '../../components/header';
export function Home() {

    useEffect(() => {
        const hash = window.location.hash;
        if (hash) {
          const element = document.querySelector(hash);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }
      }, []);

    return (
        <>
            <div style={{ background: '#ffffff' }}>
                <Header></Header>
                <Content></Content>
                <Footer></Footer>
            </div>
        </>
    );
}