import identity from '../../../assets/img/identidade-icon.png';
import backgroundCheck from '../../../assets/img/background-check-icon.png';
import bigDataCorp from '../../../assets/img/bigdatacorp.png';
import sign from '../../../assets/img/assinatura-icon.png';
import neoway from '../../../assets/img/neoway.png';
import clicksign from '../../../assets/img/clicksign.png';
import certisign from '../../../assets/img/certisign.png';
import docusign from '../../../assets/img/docusign.png';

export function ContentWhiteLabel() {
    return (
        <>
            <div className="contentLizaWL">
                <div className="headerContentWL">
                    <h3 className="subTitleWL">Tenha todas as funcionalidades da Liza em seu ambiente exclusivo</h3>
                    <span className="textDisabled">Com a Liza White Label é possível utilizar todas as funcionalidades da Liza Web
                        de forma customizada para atender as necessidades do seu negócio aliado a melhor experiência para o seu
                        cliente.</span>
                </div>

                <div className="contentLizaWLCards">
                    <div className="cardwl">
                        <img src={identity} alt="icon" />
                        <h4 className="subTextWL">Identidade Visual</h4>
                        <span className="textDisabled">Toda plataforma customizada com a sua identidade visual.</span>
                    </div>
                    <div className="cardwl">
                        <img src={backgroundCheck} alt="Background Check" />
                        <h4 className="subTextWL">Background Check personalizado</h4>
                        <span className="textDisabled">Possibilidade de escolha entre o provedor de Big Data que melhor lhe
                            convém.</span>
                        <br />
                        <a href="https://bigdatacorp.com.br/" target="_blank" rel="noopener noreferrer"><img src={bigDataCorp} alt="Big Data Corp" /></a>
                        <a href="https://www.neoway.com.br/" target="_blank" rel="noopener noreferrer"><img src={neoway} alt="Neoway" /></a>
                    </div>
                    <div className="cardwl">
                        <img src={sign} alt="icon"/>
                        <h4 className="subTextWL">Assinatura Eletrônica e Digital</h4>
                        <span className="textDisabled">Integração com o serviço de assinatura eletrônica já utilizado pela sua
                            empresa.</span>
                        <br />
                        <a href="https://www.clicksign.com/" target="_blank" rel="noopener noreferrer"><img src={clicksign} alt="Click sign" /></a>
                        <a href="https://loja.certisign.com.br/" target="_blank" rel="noopener noreferrer"><img src={certisign} alt="Certi sign" /></a>
                        <a href="https://www.docusign.com/" target="_blank" rel="noopener noreferrer"><img src={docusign} alt="Docu sign" /></a>
                    </div>
                </div>
            </div>
        </>
    )
}