import { useState } from 'react';
import logoWhite from '../../../src/assets/img/logo-landing-nav.png';
import logo from '../../../src/assets/img/logo-nav.png';
export function Header() {

    const [toggle, setToggle] = useState(false);

    window.addEventListener("scroll", function () {
        var header = document.getElementById("header-menu");
        header.classList.toggle("sticky", window.scrollY > 0)
        if (window.scrollY > 0) {
            document.getElementById("logo-id").src = `${logo}`
            document.getElementById("logo-id").style.backgroundColor = "transparent"
            document.getElementById("btn-menu-liza").style.color = "#393C50"
            document.getElementById("header-menu").style.borderBottom = "1px solid #393c50"

            const btnMenu = document.getElementById('btn-menu-liza');
            btnMenu.addEventListener('click', toggleMenu);
        } else {
            document.getElementById("logo-id").src = `${logoWhite}`
            document.getElementById("btn-menu-liza").style.color = "#ffffff"
            document.getElementById("header-menu").style.borderBottom = "none"
            const btnMenu = document.getElementById('btn-menu-liza');
            btnMenu.addEventListener('click', toggleMenu);
        }
    })

    const createAccount = () => {
        window.open('https://app.goliza.com.br/#/entry-customer-account', '_blank');
    }

    const openLogin = () => {
        window.open('https://app.goliza.com.br/#/login', '_blank');
    }

    const toggleMenu = () => {
        setToggle(!toggle);
        if (toggle) {
            document.getElementsByClassName("menuDevice")[0].style.display = "block"
            document.getElementsByClassName("menuDevice")[0].style.position = "fixed"
            document.getElementsByClassName("menuDevice")[0].style.borderTop = "1px solid #393C50"
        } else {
            document.getElementById("menu-device").style.display = "none"
        }

        // document.getElementById("menu-device").classList.toggle("active");
        // if (document.getElementById("menu-device").classList.contains("active")) {
        //     document.getElementsByClassName("active")[0].style.display = "block"
        //     document.getElementsByClassName("active")[0].style.position = "fixed"
        //     document.getElementsByClassName("active")[0].style.borderTop = "1px solid #393C50"
        // } else {
        //     document.getElementById("menu-device").style.display = "none"
        // }
    }

    const closeMenu = () => {
        setToggle(false);
        document.getElementById("menu-device").style.display = "none"
        // document.getElementById("menu-device").classList.toggle("active");
    }

    return (
        <>
            <div id="header" className="header">
                <div id="header-menu">
                    <div className="header-content">
                        <div className="logo-header">
                            <a href={"/"}>
                                <img src={logoWhite} id="logo-id" height="30" alt={"goliza"} />
                            </a>
                        </div>
                        <div className="menu-header">
                            <ul>
                                <li><a href={'#header'} className="menu-item">Início</a></li>
                                <li><a href={'#lizafunds'} className="menu-item">Liza Fundos</a></li>
                                <li><a href={'other-solutions'} className="menu-item">Outras Soluções</a></li>
                                <li><a href={'#customers'} className="menu-item">Clientes</a></li>
                                <li><a href={'#partners'} className="menu-item">Parceiros</a></li>
                                <li><a href={'#schedule-a-demo'} className="menu-item">Agende uma Demo</a></li>
                                <li><a onClick={() => openLogin()} className="login">Login</a></li>
                            </ul>
                        </div>
                        <div className="menu-header-display">
                            <button id="btn-menu-liza" onClick={() => toggleMenu()} style={{ color: "#FFFFFF" }}>
                                <span className="material-symbols-outlined">
                                    menu
                                </span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="header-description animate__animated animate__fadeIn">
                    <div className="header-info">
                        <h1>Automatize o cadastro de<br></br> fundos no mercado de capitais</h1>
                        <span>Tenha o fluxo de cadastro, desde  a coleta de dados até a sua conclusão,<br></br>
                            automatizado com as esteiras  do Liza Fundos.</span>
                    </div>
                    <div className="header-info-empty"></div>
                </div>
            </div>
            <div id="menu-device" className="animate__animated animate__fadeIn menuDevice">
                <ul>
                    {/* 
                        <li><a href={'#header'} className="menu-item" onClick={() => closeMenu()}>Início</a></li>
                        <li><a href={'#products'} className="menu-item" onClick={() => closeMenu()}>Soluções</a></li>
                        <li><a href={'whitelabel'} className="menu-item" onClick={() => closeMenu()}>Fintech as a Service</a></li>
                        <li><a href={'#customers'} className="menu-item" onClick={() => closeMenu()}>Clientes</a></li>
                        <li><a href={'#partners'} className="menu-item" onClick={() => closeMenu()}>Parceiros</a></li>
                        <li><a href={'#schedule-a-demo'} className="menu-item" onClick={() => closeMenu()}>Agende uma Demo</a></li>
                        <li><a href={'https://app.goliza.com.br/#/login'} className="login">Login</a></li> 
                    */}
                    <li><a href={'#header'} className="menu-item" onClick={() => closeMenu()}>Início</a></li>
                    <li><a href={'#lizafunds'} className="menu-item" onClick={() => closeMenu()}>Liza Fundos</a></li>
                    <li><a href={'other-solutions'} className="menu-item" onClick={() => closeMenu()}>Outras Soluções</a></li>
                    <li><a href={'#customers'} className="menu-item" onClick={() => closeMenu()}>Clientes</a></li>
                    <li><a href={'#partners'} className="menu-item" onClick={() => closeMenu()}>Parceiros</a></li>
                    <li><a href={'#schedule-a-demo'} className="menu-item" onClick={() => closeMenu()}>Agende uma Demo</a></li>
                    <li><a onClick={() => openLogin()} className="login" >Login</a></li>
                    <hr></hr>

                </ul>
            </div>
        </>
    )
}