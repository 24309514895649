import { Topbar } from './../../shareds/components/topbar/index';
import { ContentPitch } from './../../shareds/components/contentPitch/index';
export function Pitch() {
    const newTitle = "goLiza";
    document.title = newTitle
    return (
        <>
            <Topbar hiddenMenu={true}></Topbar>
            <ContentPitch></ContentPitch>
        </>
    )
}