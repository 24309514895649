import { useState } from 'react';
import logo from '../../../../src/assets/img/logo-nav.png';
import '../../../style/style.css';

export function Topbar(props) {
    const hiddenMenu = props.hiddenMenu;
    const [toggle, settoggle] = useState(false);


    const openLogin = () => {
        window.open('https://app.goliza.com.br/#/login', '_blank');
    }

    const openMenu = () => {
        settoggle(!toggle)
        if (toggle) {
            document.getElementById('menuLizaDeviceItem').style.display = "flex";
        } else {
            document.getElementById('menuLizaDeviceItem').style.display = "none";
        }
    }

    const closeMenu = () => {
        settoggle(false);
        document.getElementById('menuLizaDeviceItem').style.display = "none";
    }

    if (hiddenMenu) {
        return (
            <>
                <div className="contentHiddenMenu">
                    <div className="logoLizaHiddenMenu">
                        <img src={logo} width="112.5" height="30" alt="goLiza" />
                    </div>
                </div>
            </>
        );
    }
    return (
        <>
            <div className="content">

                <div className="logoLiza">
                    <img src={logo} width="112.5" height="30" alt="goLiza" />
                </div>

                <div className="logoLizaDevice">
                    <img src={logo} width="112.5" height="30" alt="goLiza" />
                </div>
                <nav className="menuLiza">
                    <li className="lizaItemMenu"><a className="lizaLink" href={"/"} onClick={() => closeMenu()}>Início</a></li>
                    <li className="lizaItemMenu"><a className="lizaLink" href={"/#lizafunds"} onClick={() => closeMenu()}>Liza Fundos</a></li>
                    <li className="lizaItemMenu"><a className="lizaLink" href={"/other-solutions"} onClick={() => closeMenu()}>Outras Soluções</a></li>
                    <li className="lizaItemMenu"><a className="lizaLink" href={"/#customers"} onClick={() => closeMenu()}>Clientes</a></li>
                    <li className="lizaItemMenu"><a className="lizaLink" href={"/#partners"} onClick={() => closeMenu()}>Parceiros</a></li>
                    <li className="lizaItemMenu"><a className="lizaLink" href={"/#schedule-a-demo"} onClick={() => closeMenu()}>Agende uma Demo</a></li>
                    <li className="lizaItemMenu"><a className="buttonLogin" onClick={() => openLogin()}>Login</a></li>
                </nav>
                <nav className="menuLizaDevice">
                    <button onClick={() => openMenu()}><i className="fas fa-bars fa-2x"></i></button>
                </nav>
            </div>

            <div id="menuLizaDeviceItem">
                {/* <li className="lizaItemMenu"><a className="lizaLink" href="/">Início</a></li>
                <li className="lizaItemMenu"><a className="lizaLink" href={"/#products"}>Soluções</a></li>
                <li className="lizaItemMenu"><a className="lizaLink" href={"whitelabel"}>Fintech as a Service</a></li>
                <li className="lizaItemMenu"><a className="lizaLink" href={"/#customers"}>Clientes</a></li>
                <li className="lizaItemMenu"><a className="lizaLink" href={"/#partners"}>Parceiros</a></li>
                <li className="lizaItemMenu"><a className="lizaLink" href={"/#schedule-a-demo"}>Agende uma Demo</a></li>
                <li className="lizaItemMenu"><a className="buttonLogin" href={'https://app.goliza.com.br/#/login'}>Entrar</a></li> */}

                <li className="lizaItemMenu"><a className="lizaLink" href={"/"}>Início</a></li>
                <li className="lizaItemMenu"><a className="lizaLink" href={"/#lizafunds"}>Liza Fundos</a></li>
                <li className="lizaItemMenu"><a className="lizaLink" href={"/other-solutions"}>Outras Soluções</a></li>
                <li className="lizaItemMenu"><a className="lizaLink" href={"/#customers"}>Clientes</a></li>
                <li className="lizaItemMenu"><a className="lizaLink" href={"/#partners"}>Parceiros</a></li>
                <li className="lizaItemMenu"><a className="lizaLink" href={"/#schedule-a-demo"}>Agende uma Demo</a></li>
                <li className="lizaItemMenu"><a className="buttonLogin" onClick={() => openLogin()}>Login</a></li>
            </div>
        </>
    );
}