import goLiza from '../../../assets/img/goliza.jpg';

export function FooterCVM() {
    return (
        <>
            <div className="lz-footer">
                <a href="https://www.goliza.com.br" rel="noopener noreferrer" target="_blank"><img src={goLiza} alt="goLiza" height="56"
                    width="auto" /></a>
                <p>Único local para sua empresa gerir e compartilhar fluxos de cadastros do mercado
                    financeiro.</p>

                <div className="lz-create-account">
                    <a href="https://goliza.com.br/contanova" className="lz-create-account">Crie sua conta
                        grátis</a>
                </div>
            </div>
        </>
    );
}