import { Footer } from '../../components/footer';
import { HeaderOtherSolution } from '../../shareds/components/headerOtherSolution';
import { Topbar } from '../../shareds/components/topbar/index';
import { ContentOtherSolution } from './../../shareds/components/contentOtherSolution/index';

export function OtherSolutions() {
    const newTitle = "goLiza - Outras Soluções";
    document.title = newTitle
    return (
        <>
            <Topbar hiddenMenu={false}></Topbar>
            <HeaderOtherSolution></HeaderOtherSolution>
            <div style={{background: '#ffffff'}}>
            <ContentOtherSolution></ContentOtherSolution>
            </div>
            <Footer></Footer>
        </>
    )
}