import headerImage from '../../../assets/img/goliza-loading.png'

export function TopbarCVM() {
    return (
        <>
            <div className="lz-header">
                <div className="lz-logo">
                    <img src={headerImage} alt="goLiza" height="80" width="80" />
                </div>
            </div>
        </>
    )
}